import type { DataTableHeader } from 'best-modules/components'

const userListHeaders: DataTableHeader[] = [
  {
    title: 'ПІБ',
    key: 'name',
  },
  {
    title: 'Телефон',
    key: 'phone',
    sortable: false,
  },
  {
    title: 'Email',
    key: 'email',
    sortable: false,
  },
]

const companyListHeaders: DataTableHeader[] = [
  {
    title: 'Тип',
    key: 'type.name',
  },
  {
    title: 'Назва',
    key: 'shortName',
  },
  {
    title: 'ЄДРПОУ',
    key: 'edrpou',
  },
  {
    title: 'Дії',
    key: 'actions',
    align: 'end',
  },
]

const reportListHeaders: DataTableHeader[] = [
  {
    title: 'Період',
    key: 'period',
  },
  {
    title: 'Назва',
    key: 'name',
  },
  {
    title: 'Компанія',
    key: 'company.shortName',
  },
  {
    title: 'ЄДРПОУ',
    key: 'company.edrpou',
    align: 'end',
  },
]

const packageListHeaders: DataTableHeader[] = [
  {
    title: 'Код',
    key: 'code',
  },
  {
    title: 'Клієнт',
    key: 'client',
  },
  {
    title: 'Статус',
    key: 'status',
  },
  {
    title: 'ЄДРПОУ/ІПН',
    key: 'inn',
  },
  {
    title: 'Дата створення',
    key: 'created_at',
  },
  {
    title: 'Автор',
    key: 'author',
  },
  {
    title: 'Дії',
    key: 'actions',
    align: 'end',
  },
]

const packageDirectoryHeaders: DataTableHeader[] = [
  {
    title: 'Назва показника',
    key: 'name',
  },
  {
    title: 'Код показника',
    key: 'code',
  },
  {
    title: 'Дата початку',
    key: 'openDate',
  },
  {
    title: 'Дата закінчення',
    key: 'closeDate',
  },
]

const validationTHead: DataTableHeader[] = [
  {
    title: 'Поле',
    key: 'key',
  },
  {
    title: 'Текст',
    key: 'error',
  },
]

const companyEmployeesHeaders: DataTableHeader[] = [
  { title: 'Користувач', key: 'user' },
  { title: 'Роль', key: 'role' },
  { title: 'Дії', key: 'actions', align: 'end' },
]

const userCompaniesTHead: DataTableHeader[] = [
  { title: 'Назва', key: 'company.shortName' },
  { title: 'Роль', key: 'role' },
]

const companyTokenHeaders: DataTableHeader[] = [
  { title: 'Назва', key: 'name' },
  { title: 'Дата створення', key: 'created_at' },
  { title: 'Дійсний до', key: 'expires_at' },
  { title: 'Дії', key: 'actions' },
]

export {
  userListHeaders,
  companyListHeaders,
  reportListHeaders,
  packageListHeaders,
  packageDirectoryHeaders,
  companyTokenHeaders,
  validationTHead,
  companyEmployeesHeaders,
  userCompaniesTHead,
}
