import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { VBtn, VDataTable, VOtpInput } from 'vuetify/components'
import { md3 } from 'vuetify/blueprints'
import { uk } from './i18n'
import { iconSet } from 'best-modules/icons'

const vuetify = createVuetify({
  components: {
    VDataTable,
    VOtpInput,
  },
  locale: {
    locale: 'uk',
    fallback: 'en',
    messages: { uk },
  },
  blueprint: md3,
  aliases: {
    VBtnAlt: VBtn,
    VBtnLink: VBtn,
  },
  icons: {
    defaultSet: 'mdi',
    sets: {
      best: iconSet,
    },
    aliases: {
      checkboxOn: 'best:checkbox-on-outlined',
    },
  },
  defaults: {
    VCard: {
      variant: 'flat',
    },
    VDataTable: {
      density: 'comfortable',
    },
    VTextField: {
      variant: 'outlined',
    },
    VTextarea: {
      variant: 'outlined',
    },
    VCheckbox: {
      color: 'primary',
    },
    VSelect: {
      variant: 'outlined',
    },
    VAutocomplete: {
      variant: 'outlined',
    },
    VBtn: {
      color: 'primary',
      rounded: 'sm',
    },
    VBtnAlt: {
      color: 'primary',
      variant: 'outlined',
      rounded: false,
    },
    VBtnLink: {
      color: '#3024b3',
      variant: 'plain',
      density: 'compact',
      class: ['link'],
    },
    VTooltip: {
      location: 'bottom',
    },
    VTab: {
      rounded: false,
      density: 'compact',
      size: 'x-small',
      variant: 'plain',
    },
    VSwitch: {
      density: 'compact',
    },
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          surface: '#ffffff',
          primary: '#514ef3',
          secondary: '#FBBC05',
          error: '#EC2308',
        },
      },
    },
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

export { vuetify }
