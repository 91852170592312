<template>
  <div>
    <div
      v-if="$loading.isLoading('parsePackage')"
      class="d-flex justify-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <b-input-file
      v-show="!$loading.isLoading('parsePackage')"
      v-model="file"
      name-folder="packages"
      dropper
      :error-messages="getErrorMessages(v$.data)"
      :multiple="false"
      :accept="['.xls', '.xlsx']"
      type="paths"
      label="Оберіть файл звіту"
      @update:model-value="parsePackage">
      <template #dropper-label>
        <b>
          Перетягніть сюди файл або
          <u>
            оберіть його <br />
            на своєму носієві
          </u>
        </b>
      </template>
    </b-input-file>
    <div class="mt-5 text-center">
      <div v-if="isParseSuccess === true" class="text-success">
        Дані успішно зчитані
      </div>
      <div v-if="isParseSuccess === false" class="text-error">
        Помилка зчитування данних
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { BInputFile } from 'best-modules/components'
import { PropType, reactive, ref } from 'vue'
import { Dialog } from 'best-modules/plugins/dialog/types'
import {
  createPackage,
  parsePackage as parsePackageRequest,
  updatePackage,
} from '@/request/report'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages, download, getFile } from 'best-modules/utils'
import type { PackageCredentials } from '@/utils/types'
import { handleAsync } from 'best-modules/plugins'

export default {
  name: 'Package',
  components: { BInputFile },
  props: {
    dialog: {
      type: Object as PropType<Dialog<'Package'>>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const packageData = reactive<PackageCredentials>({
      data: null,
      packageId: props.dialog.dialogData.packageId,
      reportId: props.dialog.dialogData.reportId,
    } as PackageCredentials)

    const rules = {
      data: { required },
    }

    const v$ = useVuelidate(rules, packageData)

    const isParseSuccess = ref<boolean | null>(null)
    const file = ref<string[] | null>(null)

    const submit = () => {
      if (props.dialog.dialogData.updatePackage) {
        return updatePackage(packageData.data[0].data, packageData.packageId)
      } else {
        return createPackage(packageData)
      }
    }

    const parsePackage = () => {
      if (file.value && file.value[0]) {
        handleAsync('parsePackage', () => {
          return parsePackageRequest({ file: file.value[0].substring(9) })
            .then(async res => {
              if (res) {
                packageData.data = res.parsedData
                const path = (await getFile(res.file)) as string
                await download(path, res.file.split('/').at(-1))
                isParseSuccess.value = true
              }
            })
            .catch(e => {
              isParseSuccess.value = false
              file.value = null
              return Promise.reject(e)
            })
        })
      }
    }

    return {
      packageData,
      v$,
      submit,
      parsePackage,
      isParseSuccess,
      file,
    }
  },
}
</script>

<style scoped></style>
