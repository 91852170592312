import { DialogComponent, getDefaultDialogTitle } from 'best-modules/plugins'
import User from './components/user/User.vue'
import UserPhoto from './components/user/UserPhoto.vue'
import Signature from './components/signature/SignIndex.vue'
import Company from './components/company/Company.vue'
import Report from './components/Report.vue'
import Package from './components/package/Package.vue'
import PackageDirectory from './components/package/PackageDirectoryIndex.vue'
import PackageSelectMultiple from './components/package/PackageSelectMultiple.vue'
import PackageCopy from './components/package/PackageCopy.vue'
import Confirm from './components/Confirm.vue'
import PackageManually from './components/package/PackageManually.vue'
import PackageValidationErrors from './components/package/PackageValidationErrors.vue'
import PackagePersonShort from './components/package/PackagePersonShort.vue'
import PackageSelectPersonInfo from './components/package/PackageSelectPersonInfo.vue'
import PackageAccountInfo from './components/package/PackageAccountInfo.vue'
import UserCompany from './components/user/UserCompany.vue'
import ChangeUser from './components/user/ChangeUser.vue'
import ChangeCompany from './components/user/ChangeCompany.vue'
import NBUErrors from './components/package/NBUErrors.vue'
import CompanyToken from './components/company/CompanyToken.vue'
import CompanyTokenPlainText from './components/company/CompanyTokenPlainText.vue'
type DialogName =
  | 'User'
  | 'UserPhoto'
  | 'Signature'
  | 'Company'
  | 'Report'
  | 'Package'
  | 'PackageManually'
  | 'PackageDirectory'
  | 'PackageSelectMultiple'
  | 'PackageValidationErrors'
  | 'PackageCopy'
  | 'Confirm'
  | 'PackagePersonShort'
  | 'PackageSelectPersonInfo'
  | 'ChangeCompany'
  | 'UserCompany'
  | 'ChangeUser'
  | 'NBUErrors'
  | 'PackageAccountInfo'
  | 'CompanyToken'
  | 'CompanyTokenPlainText'

const dialogComponents: Record<DialogName, DialogComponent<DialogName>> = {
  Confirm: {
    component: Confirm,
    title() {
      return 'Підтвердіть дію'
    },
  },
  User: {
    component: User,
    title() {
      return 'Створити користувача'
    },
  },
  UserCompany: {
    component: UserCompany,
    title() {
      return 'Додати компанію'
    },
  },
  ChangeUser: {
    component: ChangeUser,
    title() {
      return 'Змінити користувача'
    },
  },
  UserPhoto: {
    component: UserPhoto,
    title() {
      return 'Змінити фото'
    },
  },
  Signature: {
    component: Signature,
    hideActionButtons: true,
    width: 680,
    title() {
      return 'Підписати документ'
    },
  },
  Company: {
    component: Company,
    title() {
      return 'Створити компанію'
    },
  },
  ChangeCompany: {
    component: ChangeCompany,
    title() {
      return 'Змінити компанію'
    },
  },
  Report: {
    component: Report,
    title() {
      return 'Створити звіт'
    },
  },
  Package: {
    component: Package,
    title() {
      return 'Пакет'
    },
  },
  PackageManually: {
    component: PackageManually,
    title() {
      return 'Створити пакет вручну'
    },
  },
  PackageDirectory: {
    component: PackageDirectory,
    width: 850,
    title() {
      return 'Оберіть значення з довідника'
    },
  },
  PackageSelectMultiple: {
    component: PackageSelectMultiple,
    title() {
      return 'Введіть значення'
    },
  },
  PackageCopy: {
    component: PackageCopy,
    title() {
      return 'Копіювання пакету в звіт за період:'
    },
  },
  PackageValidationErrors: {
    component: PackageValidationErrors,
    hideActionButtons: true,
    width: 700,
    title() {
      return 'Документ має критичні помилки'
    },
  },
  PackagePersonShort: {
    component: PackagePersonShort,
    title() {
      return 'Тип особи'
    },
  },
  PackageSelectPersonInfo: {
    component: PackageSelectPersonInfo,
    title() {
      return 'Оберіть значення'
    },
  },
  NBUErrors: {
    component: NBUErrors,
    title() {
      return 'Помилки НБУ'
    },
    width: 750,
    hideActionButtons: true,
  },
  PackageAccountInfo: {
    component: PackageAccountInfo,
    title() {
      return 'Облікова інформація'
    },
  },
  CompanyToken: {
    component: CompanyToken,
    title({ action }) {
      return `${getDefaultDialogTitle(action)} токет інтеграції`
    },
  },
  CompanyTokenPlainText: {
    component: CompanyTokenPlainText,
    hideActionButtons: true,
    title() {
      return 'Токет інтеграції'
    },
  },
}

export { dialogComponents, DialogName }
