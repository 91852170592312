<template>
  <div>
    <b-section-loader :loading="$loading.isLoading('init')">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <div class="d-flex flex-column gap-2">
            <div class="d-flex gap-2 align-center justify-space-between">
              <div class="d-flex gap-2">
                <h2>№ {{ reportPackage.code }}</h2>
                <span
                  class="d-block"
                  :style="{
                    color: `rgb(var(--v-theme-${!reportPackage.isValidated ? 'error' : 'success'}))`,
                  }">
                  {{
                    reportPackage.isValidated
                      ? 'Провалідовано'
                      : 'Непровалідовано'
                  }}
                </span>
              </div>

              <div class="d-flex align-center gap-2">
                <v-btn
                  v-if="
                    userAccessRights.includes('report.package.update') &&
                    !readonly
                  "
                  v-tooltip="'Замінити пакет'"
                  icon="mdi-file-arrow-left-right"
                  color="grey"
                  variant="text"
                  density="comfortable"
                  size="small"
                  @click="updatePackageFile">
                </v-btn>

                <v-btn
                  v-tooltip="'Друкувати'"
                  icon="mdi-printer-outline"
                  color="grey"
                  variant="text"
                  density="comfortable"
                  size="small">
                </v-btn>

                <v-btn
                  v-if="
                    userAccessRights.includes('report.package.update') &&
                    !readonly
                  "
                  v-tooltip="'Зберегти пакет'"
                  icon="mdi-content-save-outline"
                  color="grey"
                  variant="text"
                  density="comfortable"
                  size="small"
                  :loading="$loading.isLoading('updatePackage')"
                  @click="updatePackage">
                </v-btn>

                <v-btn
                  v-if="userAccessRights.includes('report.package.delete')"
                  v-tooltip="'Видалити пакет'"
                  icon="mdi-trash-can-outline"
                  color="grey"
                  variant="text"
                  density="comfortable"
                  size="small"
                  @click="deletePackageMethod">
                </v-btn>
              </div>
            </div>

            <div class="d-flex gap-2 mb-3 flex-column">
              <div class="d-flex flex-column align-start">
                <span class="font-weight-bold">
                  {{ clientName }}, дані звітності станом на
                  {{ formatPeriod(reportPackage?.report?.period) }}
                </span>
              </div>
              <div
                class="validation-block"
                :style="{
                  borderLeft: `2px solid ${!reportPackage.isValidated ? '#3bad49' : '#FFB200'}`,
                }">
                <div class="d-flex align-center justify-space-between gap-5">
                  <div
                    v-if="
                      !reportPackage.isValidated &&
                      [1, 2].includes(reportPackage.statusId)
                    ">
                    <div>
                      Ви можете підписати та відправити документ тільки після
                      валідації. Перевірте заповнені поля та провалідуйте Пакет.
                    </div>
                  </div>
                  <span
                    v-if="
                      reportPackage.isValidated && reportPackage.statusId === 2
                    ">
                    Тепер ви можете підписати та відправити документ
                  </span>
                  <span v-if="reportPackage.statusId === 3">
                    Ви успішно підписали пакет, тепер він доступний для
                    надсилання
                  </span>
                  <span v-if="reportPackage.statusId === 4">
                    Пакет в процесі відправлення
                  </span>
                  <div v-if="reportPackage.statusId === 5">
                    <span>Відправлення провалено</span>
                    <div>
                      <v-btn
                        class="mt-4"
                        color="warning"
                        density="comfortable"
                        @click="sendToRevision">
                        Відправити на доопрацювання
                      </v-btn>
                    </div>
                  </div>
                  <span v-if="reportPackage.statusId === 6">
                    Ви успішно надіслали пакет.
                  </span>
                  <span v-if="reportPackage.statusId === 7">
                    Прийнято НБУ
                  </span>
                  <div v-if="reportPackage.statusId === 8">
                    <span class="mr-2">Не прийнято НБУ</span>
                    <div>
                      <v-btn
                        class="mt-4"
                        color="warning"
                        density="comfortable"
                        @click="sendToRevision">
                        Відправити на доопрацювання
                      </v-btn>
                    </div>
                  </div>
                  <span v-if="reportPackage.statusId === 9">
                    Пакет не знайдено НБУ
                  </span>
                  <span v-if="reportPackage.statusId === 10">
                    Пакет в процесі опрацювання НБУ
                  </span>
                  <span v-if="reportPackage.statusId === 11">
                    <span class="d-block mb-3">Помилка при обробці НБУ</span>
                    Необхідно відправити лист з інформацією про отримання даного
                    статусу по ідентифікатору пакету на скриньку
                    <a
                      target="_blank"
                      class="link d-inline-block"
                      :href="`mailto:pcr@bank.gov.ua?subject=${encodeURI('Помилка обробки пакету')}&body=${encodeURI(`Ідентифікатор пакету: ${reportPackage.id}`)}`">
                      pcr@bank.gov.ua
                    </a>
                    та очікувати на подальші інструкції.
                  </span>
                  <v-btn
                    v-if="
                      !reportPackage.isValidated &&
                      userAccessRights.includes('report.package.validation')
                    "
                    variant="outlined"
                    class="border-dashed"
                    color="success"
                    :loading="$loading.isLoading('validateFile')"
                    @click="validateFileHandler">
                    <v-icon color="success" class="mr-1">
                      mdi-file-check-outline
                    </v-icon>
                    Провалідувати
                  </v-btn>
                  <v-btn
                    v-if="
                      reportPackage.isValidated &&
                      reportPackage.statusId === 2 &&
                      userAccessRights.includes('electronicSignature.signature')
                    "
                    color="amber-darken-1"
                    class="text-white"
                    :loading="$loading.isLoading('signPackage')"
                    @click="signPackage">
                    <v-icon color="white" class="mr-1">mdi-draw-pen</v-icon>
                    Підписати
                  </v-btn>
                  <v-btn
                    v-if="
                      reportPackage.statusId >= 3 &&
                      reportPackage.electronic_signature
                    "
                    color="primary"
                    variant="text"
                    @click="signatureModal = true">
                    Деталі підписання
                    <v-icon class="ml-1">mdi-arrow-right-thin</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="reportPackage.statusId === 3"
                    class="border-dashed"
                    color="primary"
                    :loading="$loading.isLoading('sendPackage')"
                    @click="sendPackageMethod">
                    Надіслати пакет
                    <v-icon class="ml-1">mdi-file-send-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="[6, 9, 10].includes(reportPackage.statusId)"
                    class="border-dashed"
                    color="primary"
                    @click="getPackageStatusMethod">
                    Перевірити статус
                    <v-icon class="ml-1">mdi-passport-check</v-icon>
                  </v-btn>
                  <v-dialog
                    v-model="signatureModal"
                    max-width="450px"
                    opacity="0"
                    class="justify-end align-start"
                    content-class="mr-5 mb-0 mt-20"
                    transition="slide-x-reverse-transition">
                    <v-card
                      rounded="0"
                      elevation="2"
                      height="calc(100vh - 4.75rem)">
                      <v-card-title
                        style="font-size: 14px"
                        class="d-flex justify-space-between align-start mt-2">
                        <div class="d-flex flex-column">
                          <h2 class="font-weight-bold">Підписання</h2>
                        </div>
                        <v-btn
                          color="grey"
                          variant="text"
                          density="comfortable"
                          icon
                          @click="signatureModal = false">
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text style="font-size: 14px">
                        <SignatureStatus :package="reportPackage" />
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
                <div
                  v-if="
                    reportPackage.n_b_u_control_errors?.length &&
                    reportPackage.statusId !== 7
                  "
                  class="mb-3">
                  <span class="link d-inline" @click="NBUErrorsDialog">
                    Переглянути помилки контролю НБУ
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <DocumentPreview v-if="reportPackage.data.data" />
          </div>
        </v-col>
      </v-row>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import {
  deletePackage,
  getPackageSingle,
  updatePackage as updatePackageRequest,
  sendPackage as sendPackageRequest,
  getPackageSchemaBody,
  sendPackageToRevision,
} from '@/request/report'
import { useRoute } from 'vue-router'
import { computed, inject, reactive, provide, ref, onUnmounted } from 'vue'
import { openDialog } from '@/plugins/dialog'
import { BSectionLoader } from 'best-modules/components'
import type { Package, PackageData } from '@/utils/types'
import { rerenderKey } from '@/injection-keys'
import SignatureStatus from './signature/SignatureStatus.vue'
import DocumentPreview from './document/DocumentPreview.vue'
import { usePackageValidation } from './validation/packageValidation'
import router from '@/router'
import { set, get, cloneDeep, omit } from 'lodash'
import {
  packageDataKey,
  updatePackageDataKey,
  schemaKey,
  readonlyKey,
} from './injectionKeys'
import { usePackageDirectory } from '@/store/package-directory'
import { setSnackbar, handleAsync } from 'best-modules/plugins'
import { useBreadcrumb } from '@/plugins/breadcrumb'
import { formatPeriod } from '@/pages/report/helpers'
import { socket, packageSingleChannel } from '@/socket'
import { getPackagePersonName } from '@/pages/report/package/package-table/helpers'
import { storeToRefs } from 'pinia'
import { useUser } from '@/store/user'
import {
  formatPackageDataRequest,
  formatPackageDataResponse,
} from '@/pages/report/package/formatPackageData'

export default {
  name: 'PackageSingle',
  components: { DocumentPreview, SignatureStatus, BSectionLoader },
  methods: { formatPeriod },
  setup() {
    const { fillDirectory } = usePackageDirectory()
    const breadcrumb = useBreadcrumb()
    const route = useRoute()
    const packageId = computed<string>(() => {
      return route.params.id as string
    })
    const rerender = inject(rerenderKey)
    const { userAccessRights } = storeToRefs(useUser())
    const signatureModal = ref(false)
    const { validateFile, validationErrors } = usePackageValidation(
      packageId.value
    )
    const isPackageSended = ref(false)
    const schema = {} as Omit<PackageData, 'person_full' | 'person_short'>

    const reportPackage = reactive<Package>({} as Package)
    const setPackageData = (
      data: Package,
      options: { excludePackageData?: boolean } = {}
    ) => {
      const { excludePackageData = false } = options
      if (excludePackageData) {
        Object.assign(reportPackage, omit(data, 'data'))
      } else {
        data.data.data = formatPackageDataResponse(data.data.data)
        Object.assign(reportPackage, data)
      }
    }
    const updatePackageData = (path: string, value: any) => {
      const oldValue = get(reportPackage.data.data, path)

      console.groupCollapsed(`updatePackageData ${path}`)
      console.log('path', path)
      console.log('oldValue', oldValue)
      console.log('newValue', value)

      if (path.includes('person_id_short')) {
        const relatedPerson =
          reportPackage.data.data.person_full[0].related_person?.find(p => {
            return p.person_info.person_id_short === oldValue
          })
        if (relatedPerson) {
          relatedPerson.person_info.person_id_short = value
        }
      }
      set(reportPackage.data.data, path, value)

      console.log('packageData', reportPackage.data.data)

      console.groupEnd()
    }

    const clientName = computed(() => {
      if (
        reportPackage?.data?.data?.person_full &&
        reportPackage.data.data.person_full[0]
      ) {
        return getPackagePersonName(reportPackage.data.data.person_full[0])
      } else {
        return ''
      }
    })

    const readonly = computed<boolean>(() => {
      return ![1, 2].includes(reportPackage?.statusId)
    })

    provide(updatePackageDataKey, updatePackageData)
    provide(
      packageDataKey,
      computed(() => reportPackage.data.data)
    )
    provide(schemaKey, schema)
    provide(readonlyKey, readonly)

    handleAsync('init', async () => {
      await getPackageSingle(packageId.value).then(res => {
        if (res) {
          setPackageData(res)
          breadcrumb.set([
            {
              title: `Звітність за ${reportPackage?.report?.period}`,
              to: {
                name: 'report-single',
                params: { id: reportPackage?.reportId },
              },
              index: 1,
            },
            {
              title: `Пакет №${reportPackage.code}`,
              index: 2,
            },
          ])

          socket.on(packageSingleChannel, res => {
            console.log('Socket [ON]', cloneDeep(res))
            if (res.id === reportPackage.id) {
              setPackageData(res)
            }
          })
        }
      })
      await fillDirectory()
      await getPackageSchemaBody().then(res => {
        Object.assign(schema, res)
      })
    })

    const sendPackageMethod = async () => {
      if (!isPackageSended.value) {
        await sendPackageRequest([reportPackage.id])
        isPackageSended.value = true
      }
    }

    const getPackageStatusMethod = () => {
      openDialog({
        name: 'Signature',
        dialogData: {
          isGetPackageStatus: true,
          packages: [packageId.value],
        },
        params: {
          cb: res => {
            if (res.message) {
              rerender()
            }
          },
        },
      })
    }

    const signPackage = () => {
      openDialog({
        name: 'Signature',
        dialogData: {
          packages: [packageId.value],
        },
        params: {
          cb: res => {
            if (res.message) {
              rerender()
            }
          },
        },
      })
    }

    const updatePackageFile = () => {
      openDialog({
        name: 'Package',
        action: 'update',
        dialogData: {
          packageData: reportPackage.data.data,
          packageId: packageId,
          reportId: reportPackage.reportId,
          updatePackage: true,
        },
        params: {
          cb: res => {
            if (res.data) {
              setPackageData(res)
            }
          },
        },
      })
    }

    const updatePackage = () => {
      return handleAsync('updatePackage', () => {
        return updatePackageRequest(
          formatPackageDataRequest(reportPackage.data.data),
          packageId.value
        ).then(res => {
          setPackageData(res)
          setSnackbar({
            text: 'Пакет оновлено',
            color: 'success',
          })
        })
      })
    }

    const deletePackageMethod = () => {
      openDialog({
        name: 'Confirm',
        dialogData: {
          confirmText: 'Ви дійсно бажаєте видалити пакет?',
        },
        params: {
          cb: res => {
            if (res) {
              return deletePackage([packageId.value]).then(() => {
                return router.push({
                  name: 'report-single',
                  params: { id: reportPackage.reportId },
                })
              })
            }
          },
        },
      })
    }

    const validateFileHandler = (): Promise<void> => {
      return validateFile().then(res => {
        if (res.reportId) {
          setPackageData(res, { excludePackageData: true })
        }
      })
    }

    const NBUErrorsDialog = () => {
      openDialog({
        name: 'NBUErrors',
        dialogData: {
          NBUErrors: reportPackage.n_b_u_control_errors,
        },
      })
    }

    const sendToRevision = (): Promise<void> => {
      return sendPackageToRevision(packageId.value).then(res => {
        setPackageData(res, { excludePackageData: true })
      })
    }

    const handleBeforeUnload = event => {
      const message =
        'Всі не збережені данні будуть видалені. Ви впевнені, що хочете покинути сторінку?'
      event.returnValue = message // Для стандартных браузеров
      return message // Для некоторых других браузеров
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    onUnmounted(() => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    })

    return {
      reportPackage,
      userAccessRights,
      signPackage,
      updatePackageFile,
      updatePackage,
      deletePackageMethod,
      sendPackageMethod,
      signatureModal,
      validateFile,
      validateFileHandler,
      getPackageStatusMethod,
      validationErrors,
      clientName,
      NBUErrorsDialog,
      sendToRevision,
      readonly,
    }
  },
}
</script>

<style scoped lang="scss">
.validation-block {
  background: var(--light-grey);
  color: var(--grey);
  padding: 12px;
  border-radius: 4px;
}
</style>
