<template>
  <div>
    <div class="d-flex justify-space-between mb-3">
      <div>
        <v-app-bar-title class="font-weight-bold mb-2">{{
          $route?.meta?.title
        }}</v-app-bar-title>
        <span>Щоб переглянути, оберіть потрібний Період</span>
      </div>
      <v-btn
        v-if="userAccessRights.includes('report.create')"
        @click="createReport">
        Створити звіт
        <v-icon class="ml-2" icon="mdi-plus"></v-icon>
      </v-btn>
    </div>

    <b-server-table
      :request-func="getReportList"
      show-filters
      :headers="reportListHeaders"
      @click:row="
        $event => {
          if (userAccessRights.includes('report.single')) {
            $router.push({ name: 'report-single', params: { id: $event.id } })
          }
        }
      ">
      <template #[`item.period`]="{ item }">
        {{ formatPeriod(item.period) }}
      </template>
      <template #[`item.name`]="{ item }">
        {{ 'Звіт про кредитну заборгованість та операції' }}
      </template>
      <template #filters>
        <b-server-filter-item
          v-slot="{ props }"
          query-key="months[]"
          directory="months">
          <filter-select
            label="Місяць"
            v-bind="props"
            :items="props.items.sort(sortMonths)">
            <template #selection="{ index }">
              <span v-if="index === 0">{{
                getSelectionLabel(props.modelValue)
              }}</span>
            </template>
            <template #item="{ props: itemProps, item }">
              <v-list-item
                :active="keepArray(props.modelValue).includes(item.raw.id)"
                :base-color="Array.isArray(item.raw.id) ? 'blue-grey' : null"
                v-bind="itemProps">
              </v-list-item>
            </template>
          </filter-select>
        </b-server-filter-item>
        <b-server-filter-item
          v-slot="{ props }"
          query-key="year"
          directory="yearsFrom2024">
          <filter-select
            v-bind="props"
            label="Рік"
            item-value="year"
            item-title="year"></filter-select>
        </b-server-filter-item>
      </template>
    </b-server-table>
  </div>
</template>

<script lang="ts">
import { openDialog } from '@/plugins/dialog'
import { getReportList } from '@/request/report'
import { BServerTable, BServerFilterItem } from 'best-modules/components'
import { keepArray } from 'best-modules/utils/helpers'
import { getDirectory } from '@/directory'
import { sortMonths } from '@/directory/local'
import { reportListHeaders } from '@/utils/tableHeaders'
import { rerenderKey } from '@/injection-keys'
import { inject } from 'vue'
import { formatPeriod } from './helpers'
import FilterSelect from '@/components/inputs/filter-select.vue'
import { useUser } from '@/store/user'
import { storeToRefs } from 'pinia'

export default {
  name: 'ReportIndex',
  components: {
    FilterSelect,
    BServerTable,
    BServerFilterItem,
  },
  data() {
    return {
      reportListHeaders,
    }
  },
  methods: {
    openDialog,
    keepArray,
    sortMonths,
    formatPeriod,
  },
  setup() {
    const rerender = inject(rerenderKey)
    const { userAccessRights } = storeToRefs(useUser())

    const createReport = () => {
      openDialog({
        name: 'Report',
        params: {
          cb: rerender,
        },
      })
    }

    const getSelectionLabel = selectedMonth => {
      const collection = getDirectory('months', null, { type: 'collection' })

      const item = collection.getItem(String(selectedMonth))

      return item?.name
    }

    return { getReportList, createReport, getSelectionLabel, userAccessRights }
  },
}
</script>
