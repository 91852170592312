<template>
  <div>
    <div class="d-flex justify-end mb-3">
      <v-btn @click="createCompanyToken">
        Створити токен
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="companyTokenHeaders"
      :items-per-page="-1"
      :items="localCompany.integration_tokens">
      <template #bottom> </template>
      <template #[`item.created_at`]="{ item }">
        <span>{{
          new CustomDate(item.created_at.substring(0, 19)).toString({
            time: true,
          })
        }}</span>
      </template>
      <template #[`item.expires_at`]="{ item }">
        <span>{{
          new CustomDate(item.expires_at.substring(0, 19)).toString()
        }}</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <b-action-menu
          :actions="[
            {
              title: 'Редагувати',
              icon: 'mdi-pencil',
              action: () => updateCompanyToken(item),
            },
            {
              title: 'Видалити',
              icon: 'mdi-delete-outline',
              action: () => deleteCompanyToken(item.id),
            },
          ]"></b-action-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { CachedObjectProxy, CustomDate } from 'best-modules/utils'
import { Company, CompanyToken } from '@/utils/types'
import { companyTokenHeaders } from '@/utils/tableHeaders'
import { deleteCompanyToken as deleteCompanyTokenRequest } from '@/request/company'
import { openDialog } from '@/plugins/dialog'
import { BActionMenu } from 'best-modules/components'
import { confirm } from 'best-modules/plugins/confirm/plugin'

export default {
  name: 'IntegrationTokens',
  components: { BActionMenu },
  emits: ['update:company'],
  props: {
    company: {
      type: Object as PropType<CachedObjectProxy<Company>>,
      required: true,
    },
  },
  data: () => {
    return { companyTokenHeaders }
  },
  methods: { CustomDate },
  setup(props, { emit }) {
    const localCompany = computed({
      get: () => props.company,
      set: val => emit('update:company', val),
    })

    const createCompanyToken = () => {
      openDialog({
        name: 'CompanyToken',
        action: 'create',
        dialogData: {
          companyId: localCompany.value.id,
        },
        params: {
          cb: (newToken: CompanyToken) => {
            if (!localCompany.value.integration_tokens) {
              localCompany.value.integration_tokens = []
            }
            localCompany.value.integration_tokens.push(newToken)
          },
        },
      })
    }
    const updateCompanyToken = (token: CompanyToken) => {
      openDialog({
        name: 'CompanyToken',
        action: 'update',
        dialogData: {
          token,
          companyId: localCompany.value.id,
        },
        params: {
          cb: (newToken: CompanyToken) => {
            localCompany.value.integration_tokens =
              localCompany.value.integration_tokens.map(t => {
                return t.id === token.id ? newToken : t
              })
          },
        },
      })
    }
    const deleteCompanyToken = (tokenId: number) => {
      confirm({
        text: 'Ви впевнені що хочете назавжди видалити токен інтеграції?',
      }).then(() => {
        return deleteCompanyTokenRequest(tokenId).then(() => {
          localCompany.value.integration_tokens =
            localCompany.value.integration_tokens.filter(t => t.id !== tokenId)
        })
      })
    }

    return {
      createCompanyToken,
      updateCompanyToken,
      deleteCompanyToken,
      localCompany,
    }
  },
}
</script>

<style scoped lang="scss"></style>
