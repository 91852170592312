<template>
  <v-app-bar class="pl-9 pr-4" density="comfortable" border>
    <v-app-bar-title>
      <Logotype />
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <div class="mr-15 d-flex align-center gap-2">
      <v-btn
        v-if="userData.isAdmin"
        v-tooltip="'Оновити схеми валідаца'"
        class="ma-0"
        icon
        :loading="$loading.isLoading('updateValidationsSchemas')"
        variant="plain"
        density="comfortable"
        size="small"
        @click="validationsSchemasUpdate">
        <v-icon color="var(--grey)">mdi-reload</v-icon>
      </v-btn>
      <v-menu
        v-model="contactMenu"
        :close-on-content-click="false"
        location="end"
        offset="0">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="ma-0"
            icon
            variant="plain"
            density="comfortable"
            size="small">
            <v-icon color="var(--grey)">mdi-face-agent</v-icon>
          </v-btn>
        </template>
        <div class="contact-menu">
          <div class="contact-menu-container">
            <div class="block-link">
              <a
                class="d-flex align-center"
                href="https://t.me/cr20nbu"
                target="_blank">
                <Telegram class="d-block mr-1" />
                <span><b>Telegram</b></span></a
              >
            </div>
            <div class="block-link">
              <Viber class="d-block mr-1" />
              <span><b>Viber</b></span>
            </div>
            <v-divider></v-divider>
            <div class="block-link">
              <v-icon class="d-block mr-1">mdi-email-outline</v-icon>
              <span
                ><a href="mailto:support@best-express-it.com.ua"
                  ><b>support@best-express-it.com.ua</b></a
                ></span
              >
            </div>
            <div class="block-link">
              <v-icon class="d-block mr-1">mdi-phone-outline</v-icon>
              <span
                ><a href="tel:+380664412544"
                  ><b>+380 (66) 441 25 44</b></a
                ></span
              >
            </div>
          </div>
        </div>
      </v-menu>
      <v-btn
        class="ma-0"
        icon
        variant="plain"
        density="comfortable"
        size="small">
        <v-icon color="var(--grey)">mdi-lightbulb-outline</v-icon>
      </v-btn>
      <v-btn
        class="ma-0"
        icon
        variant="plain"
        density="comfortable"
        size="small">
        <v-icon color="var(--grey)">mdi-bell-outline</v-icon>
      </v-btn>
    </div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="end"
      offset="0">
      <template #activator="{ props }">
        <div v-bind="props" class="header-menu-top mr-4">
          <div>
            <v-icon color="text-blue-grey-lighten-2">
              mdi-briefcase-variant-outline
            </v-icon>
          </div>
          <div class="header-menu-top-info">
            <span
              ><strong>{{
                userData.watch_company_user_role?.company?.edrpou || ''
              }}</strong></span
            >
            <span>{{
              userData.watch_company_user_role?.company?.shortName
            }}</span>
          </div>
          <div>
            <v-icon color="text-blue-grey-lighten-2">{{
              menu ? 'mdi-chevron-up' : 'mdi-chevron-down'
            }}</v-icon>
          </div>
        </div>
      </template>
      <div class="header-menu-bottom">
        <div class="header-menu-bottom-container">
          <div class="item">
            <div class="item-img">
              <BaseImage
                :aspect-ratio="1"
                height="30"
                rounded
                :src="userData.photo ? userData.photo[0] : null"
                width="30" />
            </div>
            <div class="d-flex w-100 justify-space-between align-center">
              <div>
                <span
                  ><strong>{{
                    getPersonName(userData, { initials: true })
                  }}</strong></span
                >
                <span class="link" @click="navigateToUserSingle"
                  >Налаштування профілю</span
                >
              </div>
              <v-btn
                v-if="
                  userAccessRights.includes('user.changeUser') &&
                  userData.isAdmin
                "
                v-tooltip="'Змінити користувача'"
                size="x-small"
                icon
                @click="changeUser">
                <v-icon>mdi-account-convert</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="item">
            <div class="item-img">
              <v-icon color="text-blue-grey-lighten-2"
                >mdi-briefcase-variant-outline</v-icon
              >
            </div>
            <div class="w-100">
              <div class="d-flex w-100 justify-space-between align-center">
                <div class="d-flex flex-column">
                  <span
                    ><strong>{{
                      userData.watch_company_user_role?.company?.edrpou
                    }}</strong></span
                  >
                  <span>{{
                    userData.watch_company_user_role?.company?.shortName
                  }}</span>
                  <span class="text-blue-grey-lighten-2">{{
                    userData?.isAdmin ? 'Адміністратор' : 'Користувач'
                  }}</span>
                </div>
                <div
                  v-if="
                    userAccessRights.includes('user.changeCompany') &&
                    userData.company_user_roles.length > 1
                  ">
                  <v-btn
                    v-tooltip="'Змінити компанію'"
                    size="x-small"
                    icon
                    @click="changeCompany">
                    <v-icon>mdi-domain</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-img">
              <v-icon>mdi-currency-uah</v-icon>
            </div>
            <div>
              <span><strong>Тарифний план: </strong>Професійний</span>
            </div>
          </div>
          <div v-if="userAccessRights.includes('company.single')" class="item">
            <div class="item-img">
              <v-icon>mdi-cog-outline</v-icon>
            </div>
            <div>
              <span class="link" @click="navigateToUserCompany"
                >Налаштування компанії</span
              >
            </div>
          </div>
          <v-btn
            variant="outlined"
            color="primary"
            class="w-50"
            @click="logout">
            <v-icon class="mr-1">mdi-logout</v-icon>
            Вийти
          </v-btn>
        </div>
      </div>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { useAuth } from '@/store/auth/auth.ts'
import { useUser } from '@/store/user.ts'
import { ref } from 'vue'
import BaseImage from '@/components/BaseImage.vue'
import Logotype from '@/components/Logotype.vue'
import { openDialog } from '@/plugins/dialog/index.ts'
import Telegram from '@/assets/images/Telegram.vue'
import Viber from '@/assets/images/Viber.vue'
import router from '@/router/index.js'
import { storeToRefs } from 'pinia'
import { updateValidationsSchemas } from '@/request/report.ts'
import { setSnackbar } from 'best-modules/plugins'
import { getPersonName } from 'best-modules/utils'

export default {
  name: 'Header',
  components: { Viber, Telegram, Logotype, BaseImage },
  methods: { getPersonName },
  setup() {
    const { userData } = useUser()
    const { userAccessRights } = storeToRefs(useUser())
    const { logout } = useAuth()
    const menu = ref(false)
    const contactMenu = ref(false)

    const validationsSchemasUpdate = () => {
      return updateValidationsSchemas().then(res => {
        if (res) {
          setSnackbar({ text: 'Схеми валідації оновлено' })
        }
      })
    }

    const changeUser = () => {
      openDialog({
        name: 'ChangeUser',
      })
    }

    const changeCompany = () => {
      openDialog({
        name: 'ChangeCompany',
      })
    }

    const navigateToUserSingle = () => {
      return router.push({
        name: 'profile',
      })
    }

    const navigateToUserCompany = () => {
      return router.push({
        name: 'user-company',
      })
    }

    return {
      logout,
      userData,
      menu,
      contactMenu,
      changeUser,
      changeCompany,
      userAccessRights,
      navigateToUserSingle,
      navigateToUserCompany,
      validationsSchemasUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.header-menu-top {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-info {
    padding: 0 20px 0 12px;
    span {
      font-size: 12px;
      display: block;
    }
  }
}
.header-menu-bottom {
  background: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.0588235294);
  position: absolute;
  border-radius: 0 0 8px 8px;
  right: 0;
  z-index: 1000;
  &-container {
    padding: 16px 19px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 12px;
    min-width: 20rem;
    .item {
      display: flex;
      align-items: center;
      transition: 0.3s;
      cursor: default;
      &-img {
        margin-right: 16px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.contact-menu {
  background: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.0588235294);
  position: absolute;
  border-radius: 4px;
  right: 0;
  z-index: 1000;
  top: 45px;
  border-top: 2px solid green;
  &-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 12px;
    min-width: 20rem;

    .block-link {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
      padding: 5px;

      &:hover {
        background: #e6e6e6;
      }
    }
  }
}
</style>
